
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toggle']

  toggle(ev) {
    ev.preventDefault()

    ev.currentTarget.parentElement.classList.toggle('rounded-bottom')
    this.toggleTargets.forEach((el) => el.classList.toggle('d-none'))
  }
}
