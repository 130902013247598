import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['start', 'end', 'allDay']

  select(ev) {
    this.show()

    if (this.hasStartTarget) {
      this.startTarget.value = ev.detail.start.toLocaleString('sv-SE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
      }).concat('T00:00:00')
    }

    if (this.hasEndTarget) {
      // Date is 00:00:00 of the next day...
      ev.detail.end.setSeconds(ev.detail.end.getSeconds() - 1)

      this.endTarget.value = ev.detail.end.toLocaleString('sv-SE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
      }).concat('T23:59:00')
    }

    // if (this.hasAllDayTarget) {
    //   this.allDayTarget.checked = ev.detail.allDay
    // }
  }

  show() {
    window.jQuery(this.element).modal('show')
  }
}
