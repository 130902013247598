import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['options']

  connect() {
    const currentOption = this.element.querySelector('input[data-action$="updateOptions"]:checked')?.value 

    if (currentOption) {
      this.refreshOptions(currentOption)
    }
  }

  updateOptions(ev) {
    this.refreshOptions(ev.target.value)
  }

  refreshOptions(selected) {
    this.optionsTargets.forEach((el) => {
      if (el.dataset.showFor.indexOf(selected) > -1) {
        el.removeAttribute('hidden')
        el.querySelectorAll('input[required][disabled]').forEach((input) => input.disabled = false)
      } else {
        el.setAttribute('hidden', true)
        el.querySelectorAll('input[required]:not([disabled])').forEach((input) => input.disabled = true)
      }
    })
  }

  remove() {
    this.element.classList.add('bounceOutRight')
    this.element.classList.add('height-zero')
  }
}
