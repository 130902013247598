import { Controller } from 'stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ['orderInput', 'form']
  static values = {
    group: String,
    handle: String
  }

  connect() {
    this.onEnd(null, true)

    this.sortable = Sortable.create(this.element, {
      group: this.group,
      handle: this.handleValue,
      filter: '.drag-disabled',
      forceFallback: true, // Tests fail w/o this :\
      onStart: this.onStart.bind(this), 
      onEnd: this.onEnd.bind(this)
    })
  }

  onStart(_ev) {
    this.element.classList.add('drag-in-progress')
    document.body.classList.add('text-select-none') 
  }

  onEnd(_ev, skipSubmit) {
    this.element.classList.remove('drag-in-progress')
    document.body.classList.remove('text-select-none')

    this.orderInputTargets.forEach((el, i) => {
      el.value = i + 1
    })

    if (this.hasFormTarget && !skipSubmit) {
      this.submitForm()
    }
  }

  async submitForm() {
    const data = new FormData(this.formTarget)

    try {
      await fetch(this.formTarget.action, {
        method: this.formTarget.method,
        headers: {
          'Accept': 'application/json',
        },
        body: data
      })

    } catch(e) {
      console.error(e)
    }
  }

  get group() {
    return this.groupValue || 'shared'
  }
}
