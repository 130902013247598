import { Controller } from 'stimulus'

export default class extends Controller {

  async submit(ev) {
    ev.preventDefault()

    const data = new FormData(ev.target)

    if (ev.submitter) {
      data.append(ev.submitter.name, ev.submitter.value)
    }

    const response = await fetch(ev.target.action, {
      method: ev.target.method,
      body: data,
      headers: {
        'Accept': 'text/javascript'
      }
    })

    if (response.ok) {
      const html = await response.text()
      const template = document.createElement('template')
      template.innerHTML = html
      this.element.parentElement.replaceChild(template.content, this.element)
    }
  }
}
