import { Controller } from 'stimulus'

export default class extends Controller {
  static buttonHTML = '<button type="button" class="trix-button trix-button--icon trix-button--icon-insert-variable" data-toggle="modal" data-target="#insert-template-variable-modal" title="Insert Dynamic Variable" tabindex="-1">Insert Dynamic Variable</button>'

  connect() {
    if (this.element.trixId) {
      this.setup()
    } else {
       this.element.addEventListener('trix-initialize', () => this.setup())
    }
  }

  setup() {
    this.toolbar = document.getElementById(this.element.getAttribute('toolbar'))
    this.insertToolbarButton()

    this.button.addEventListener('click', () => {
      document.getElementById('insert-template-variable-modal').insertFocus = this.element
    })
  }

  insertToolbarButton() {
    const buttonGroup = this.toolbar.querySelector('.trix-button-group--text-tools')
    buttonGroup.insertAdjacentHTML('beforeend', this.constructor.buttonHTML)

    this.button = buttonGroup.querySelector('.trix-button--icon-insert-variable')
  }
}
