import { Controller } from 'stimulus'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'
import 'tippy.js/animations/scale.css'

export default class extends Controller {
  static values = {
    eventsUrl: String,
    initialDate: String,
    selectableId: String
  }

  connect() {
    this.calendar = new Calendar(this.element, {
      plugins: [ dayGridPlugin, listPlugin, interactionPlugin ],
      initialView: this.initialView,
      initialDate: this.initialDate,
      selectable: this.hasSelectableIdValue,
      contentHeight: 'auto',
      eventSources: [
        {
          url: this.eventsUrlValue
        }
      ],
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,listMonth'
      },
      views: {
        dayGridMonth: { titleFormat: { year: 'numeric', month: 'short' }, dayMaxEventRows: 4 },
        listMonth: { titleFormat: { year: 'numeric', month: 'short' } },
      },
      eventDidMount: this.createToolTip,
      select: this.select.bind(this)
    })

    this.calendar.render()
  }

  select(info) {
    const selectedOutlet = document.querySelector(this.selectableIdValue)

    if (selectedOutlet) {
      const event = new CustomEvent('calendar:select', { detail: info, bubbles: false })
      selectedOutlet.dispatchEvent(event)
    }
  }

  createToolTip(info) {
    return tippy(info.el, {
      theme: 'light-border',
      interactive: true,
      interactiveDebounce: 75,
      maxWidth: 'clamp(300px, 500px, calc(100vw - 20px))',
      animation: 'scale',
      trigger: 'click',
      content: info.event.extendedProps.tooltip,
      allowHTML: true,
      appendTo: () => document.body,
      onShown(instance) {
        instance.popper.addEventListener('click', (ev) => {
          if (ev.target.closest('.close-tooltip')) {
            instance.hide()
          }
        })

        document.addEventListener('turbolinks:click', () => instance.destroy(), { once: true })
      }
    })
  }

  get initialView() {
    if (window.innerWidth < 576) {
      return 'listMonth'
    } else {
      return 'dayGridMonth'
    }
  }

  get initialDate() {
    if (this.hasInitialDateValue) {
      return this.initialDateValue
    } else {
      return new Date()
    }
  }
}
