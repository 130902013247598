import { Controller } from 'stimulus'
import Litepicker from 'litepicker'
import 'litepicker/dist/plugins/ranges'

export default class extends Controller {
  static targets = ['start', 'end']
  static values = {
    ranges: Array
  }

  connect() {
    const ranges = this.ranges
    const plugins = []

    if (Object.keys(ranges).length > 0) {
      plugins.push('ranges')
    }

    this.picker = new Litepicker({
      element:         this.startTarget,
      elementEnd:      this.endTargetElement,
      numberOfMonths:  this.numberOfMonths,
      numberOfColumns: this.numberOfColumns,
      startDate:       this.startDate,
      endDate:         this.endDate,
      singleMode:      this.singleMode,
      allowRepick:     true,
      firstDay:        0,
      format:          'MM/DD/YYYY',
      minDate:         this.minDate,
      plugins:         plugins,
      ranges:          { customRanges: ranges }
    })

    this.startTarget.setAttribute('readonly', true)

    if (this.hasEndTarget) {
      this.endTarget.setAttribute('readonly', true)
    }

    this.picker.on('selected', (ev) => {
      const event = new CustomEvent('datepicker:selected', {
        detail: { event: ev }, bubbles: true
      })

      this.startTarget.dispatchEvent(event)

      const form = this.element.closest('form')

      if (form) {
        const event = new Event('change', { bubbles: true })

        form.dispatchEvent(event)
      }
    })

    this.startTarget.setAttribute('pattern', '[0-9]{2}/[0-9]{2}/[0-9]{4}')
    this.startTarget.setAttribute('title', 'Date in MM/DD/YYYY format')
    this.startTarget.setAttribute('placeholder', 'MM/DD/YYYY')

    if (this.hasEndTarget) {
      this.endTarget.setAttribute('pattern', '[0-9]{2}/[0-9]{2}/[0-9]{4}')
      this.endTarget.setAttribute('title', 'Date in MM/DD/YYYY format')
      this.endTarget.setAttribute('placeholder', 'MM/DD/YYYY')
    }
  }

  show() {
    this.picker.show()
  }

  get numberOfMonths() {
    return parseInt(
      this.data.get('numberOfMonths') || '1'
    )
  }

  get numberOfColumns() {
    return parseInt(
      this.data.get('numberOfColumns') || '1'
    )
  }

  get startDate() {
    return this.startTarget.value
  }

  get endDate() {
    if (this.hasEndTarget) {
      return this.endTarget.value
    } else {
      return null
    }
  }

  get singleMode() {
    return !this.hasEndTarget
  }

  get endTargetElement() {
    if (this.hasEndTarget) {
      return this.endTarget
    } else {
      return null
    }
  }

  get minDate() {
    return this.data.get('minDate')
  }

  get ranges() {
    const allRanges = this.allRanges
    return Object.fromEntries(this.rangesValue.map(key => [key, allRanges[key]]))
  }

  get allRanges() {
    const today = new Date()

    return {
      'This Week': [this.startOfWeek(today), this.endOfWeek(today)],
      'This Month': [this.startOfMonth(today), this.endOfMonth(today)],
      'Last Month': this.lastMonthRange(today),
      'Year to Date': [this.startOfYear(today), today],
      'This Year': [this.startOfYear(today), this.endOfYear(today)],
      'Last Year': this.lastYearRange(today),
      'Last 7 Days': [this.addDays(today, -7), today],
      'Last 30 Days': [this.addDays(today, -30), today],
      'Last 60 Days': [this.addDays(today, -60), today],
      'Last 90 Days': [this.addDays(today, -90), today],
      'Next Month': this.nextMonthRange(today),
      'Next Two Months': this.nextTwoMonthsRange(today),
      'Next 15 Days': [today, this.addDays(today, 15)],
      'Next 30 Days': [today, this.addDays(today, 30)],
      'Next 45 Days': [today, this.addDays(today, 45)],
    }
  }

  startOfWeek(date) {
    const day = date.getDay() === 0 ? 7 : date.getDay() // Treat Sunday as 7
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - day + 1)
  }

  endOfWeek(date) {
    const start = this.startOfWeek(date)
    return new Date(start.getFullYear(), start.getMonth(), start.getDate() + 6)
  }

  startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1)
  }

  endOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
  }

  startOfYear(date) {
    return new Date(date.getFullYear(), 0, 1)
  }

  endOfYear(date) {
    return new Date(date.getFullYear(), 11, 31)
  }

  addDays(date, days) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days)
  }

  lastMonthRange(date) {
    const lastMonthDate = new Date(date.getFullYear(), date.getMonth() - 1, 1)
    const start = new Date(lastMonthDate.getFullYear(), lastMonthDate.getMonth(), 1)
    const end = new Date(lastMonthDate.getFullYear(), lastMonthDate.getMonth() + 1, 0)
    return [start, end]
  }

  lastYearRange(date) {
    const lastYear = date.getFullYear() - 1
    const start = new Date(lastYear, 0, 1)
    const end = new Date(lastYear, 11, 31)
    return [start, end]
  }

  nextMonthRange(date) {
    const start = new Date(date.getFullYear(), date.getMonth() + 1, 1)
    const end = new Date(date.getFullYear(), date.getMonth() + 2, 0)
    return [start, end]
  }

  nextTwoMonthsRange(date) {
    const start = new Date(date.getFullYear(), date.getMonth() + 1, 1)
    const end = new Date(date.getFullYear(), date.getMonth() + 3, 0)
    return [start, end]
  }
}
