/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Turbolinks from 'turbolinks'
import TurbolinksPrefetch from 'turbolinks-prefetch'
import Rails from '@rails/ujs'
import Trix from 'trix'

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

import Popover from '../util/popover'

window.HOALApplication = Application.start()
const context = require.context('controllers/application', true, /.js$/)
HOALApplication.load(definitionsFromContext(context))

const sharedContext = require.context('controllers/shared', true, /.js$/)
HOALApplication.load(definitionsFromContext(sharedContext))

if (window.Honeybadger != undefined) {
  HOALApplication.handleError = (error, message, detail) => {
    console.warn(message, detail)
    Honeybadger.notify(error)
  }
}

Turbolinks.start()
TurbolinksPrefetch.start()
Rails.start()
Popover()
Trix.start // eslint :\
