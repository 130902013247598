import { Controller } from 'stimulus'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'
import 'tippy.js/animations/scale.css'

export default class extends Controller {
  static targets = ['tmpl']

  connect() {
    tippy(this.element, {
      theme: 'light-border',
      interactive: true,
      interactiveDebounce: 75,
      maxWidth: 'clamp(300px, 500px, calc(100vw - 20px))',
      animation: 'scale',
      trigger: 'click',
      content: this.tmplTarget.innerHTML,
      allowHTML: true,
      appendTo: () => document.body,
      onShown(instance) {
        instance.popper.addEventListener('click', (ev) => {
          if (ev.target.closest('.close-tooltip')) {
            instance.hide()
          }
        })

        document.addEventListener('turbolinks:click', () => instance.destroy(), { once: true })
      }
    })
  }
}
