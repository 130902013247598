import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['bank', 'card', 'unselected', 'bankSelected', 'cardSelected', 'newPaymentMethod']

  connect() {
    this.updatePaymentType()
  }

  updatePaymentType() {
    const checked = this.element.querySelector('input[type="radio"][data-action~="pays#updatePaymentType"]:checked')

    if (checked) {
      this.hideUnselected()

      if (checked.value == 'bank') {
        this.hideCard()
        this.showBank()
      } else {
        this.hideBank()
        this.showCard()
      }

      this.toggleNewPaymentMethod(checked.value)
    }
  }

  toggleNewPaymentMethod(paymentType) {
    const newPaymentMethod = this.element.querySelector(`input[name="${paymentType}_payment_method_id"][value="new_${paymentType}"]:checked`)

    if (newPaymentMethod != null) {
      this.showNewPaymentMethod()
    } else {
      this.hideNewPaymentMethod()
    }
  }

  updateNewPaymentMethod(ev) {
    if (ev.target.value.includes('new')) {
      this.showNewPaymentMethod()
    } else {
      this.hideNewPaymentMethod()
    }
  }

  showNewPaymentMethod() {
    this.newPaymentMethodTargets.forEach((el) => el.removeAttribute('hidden'))
  }

  hideNewPaymentMethod() {
    this.newPaymentMethodTargets.forEach((el) => el.setAttribute('hidden', true))
  }
  showBank() {
    this.bankSelectedTargets.forEach((el) => el.removeAttribute('hidden'))
  }

  showCard() {
    this.cardSelectedTargets.forEach((el) => el.removeAttribute('hidden'))
  }

  hideUnselected() {
    this.unselectedTargets.forEach((el) => el.setAttribute('hidden', true))
  }

  hideBank() {
    this.bankSelectedTargets.forEach((el) => el.setAttribute('hidden', true))
  }

  hideCard() {
    this.cardSelectedTargets.forEach((el) => el.setAttribute('hidden', true))
  }
}

