import { Controller } from 'stimulus'

const upKey = 38
const downKey = 40
const enterKey = 13

export default class extends Controller {
  static targets = ['query', 'results']

  connect() {
    this.submitting = false
    this.element.addEventListener('autocomplete:navigate', ev => {
      this.select(ev.target)
      this.navigate(ev)
    })
  }

  disconnect() {
    this.reset()
  }

  submit() {
    this.submitting = true
    this.abortPreviousFetchRequest()
  }

  queryResults() {
    this.debounce(this.fetchResults.bind(this), 150)  
  }

  fetchResults() {
    if (this.submitting == true) {
      return
    }

    if (this.query == '') {
      this.abortPreviousFetchRequest()
      this.reset()
      return
    }

    if (this.query == this.previousQuery) {
      return
    }

    if (this.query.length < 3) {
      return
    }

    this.previousQuery = this.query

    const url = new URL(this.data.get('url'))
    url.searchParams.append('q', this.query)

    this.abortPreviousFetchRequest()

    this.abortController = new AbortController()
    fetch(url, { signal: this.abortController.signal })
      .then(response => response.json())
      .then(json => {
        this.resultsTarget.innerHTML = ''
        this.selected = null

        json.forEach(result => {
          this.resultsTarget.insertAdjacentHTML(
            'beforeend',
            `<li class="autocomplete-result" data-controller="autocomplete-result" data-action="click->autocomplete-result#navigate" data-path="${result.path}">${result.title}</li>`
          )
        })
      }).catch(() => true)
  }

  navigateResults(event) {
    switch(event.keyCode) {
      case downKey:
        this.selectNextResult()
        break
      case upKey:
        this.selectPreviousResult()
        break
      case enterKey:
        this.navigate(event)
        break
    }
  }

  debounce(func, wait) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout)
    }

    this.debounceTimeout = setTimeout(func, wait)
  }

  // private

  navigate(event) {
    if (this.selected) {
      this.submitting = true

      if (this.selected.dataset.path) {
        event.preventDefault()
        Turbolinks.visit(this.selected.dataset.path, { action: 'advance' })
      } else {
        this.queryTarget.value = this.selected.innerText
      }
      this.abortPreviousFetchRequest()
    }
  }

  reset() {
    this.resultsTarget.innerHTML = ''
    this.queryTarget.value = ''
    this.previousQuery = null
    this.selected = null
    this.submitting = false
  }

  abortPreviousFetchRequest() {
    if (this.abortController) {
      this.abortController.abort()
    }
  }

  selectNextResult() {
    if (this.selected == null) {
      this.select(this.resultsTarget.children[0])
    } else {
      this.selected.classList.remove('selected')
      this.select(this.selected.nextElementSibling)
    }
  }

  selectPreviousResult() {
    if (this.selected == null) {
      this.select(this.resultsTarget.children[0])
    } else {
      this.selected.classList.remove('selected')
      this.select(this.selected.previousElementSibling)
    }
  }

  select(element) {
    this.selected = element

    if (this.selected) {
      this.selected.classList.add('selected')
    }
  }

  get query() {
    return this.queryTarget.value
  }
}
